import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { getText } from "../../lang/language";
import "./video-banner.component.scss";

const VideoBanner = (props) => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "500px",
        margin: "0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: props.color,
      }}
    >
        <div
          style={{
            width: "80%",
          }}
        >
          <h1 style={{ color: "white", padding: "40px 0 0 0" }}>
            {getText("take_a_look_and_subscribe_to_our_YouTube_channel")}
          </h1>
          <Link href={props.link} target="_blank" underline="none">
            <Button
              startIcon={<PlayArrowIcon />}
              variant="contained"
              component="label"
              sx={{
                color: "white",
                backgroundColor: "#e52f32",
                border: "1px solid #fff",
                boxShadow: "none",
                borderColor: "red",
                borderRadius: "15px",
                maxWidth: "240px",
                padding: "8px 16px",
                fontFamily: "HelveticaNeue-BoldCondensed",
                fontSize: "22px",
                margin: "0 auto 20px 0",
                "&:hover": {
                  backgroundColor: '#e52f32',
                  opacity: "0.9",
                  boxShadow: "none",
                },
              }}
              disableRipple={true}
            >
             {getText("go_to_channel")}
            </Button>
          </Link>
          <Grid container style={{ margin: "0 0 40px 0" }}>
            {props?.videos
              ? props.videos?.map((video) => (
                  <Grid xs={12} sm={12} md={4} style={{ margin: "0 0 10px 0" }}>
                    <iframe
                      width="95%"
                      height="260px"
                      src={video}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{ borderRadius: "30px" }}
                    ></iframe>
                  </Grid>
                ))
              : null}
          </Grid>
        </div>
    </div>
  );
};

export default VideoBanner;
