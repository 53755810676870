import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./config";
import Catalogo from "./pages/catalogo/catalogo.container";
import Contact from "./pages/contact/contact.container";
import Knowledge from "./pages/knowledge/knowledge.container";
import Landing from "./pages/landing/landing.container";
import Learning from "./pages/learning/learning.container";
import Login from "./pages/login/login";
import SPV from "./pages/SPV/spv.container";
import Tickets from "./pages/tickets/tickets.container";
import { getUser } from "./services/usuarios.service";
import { change, getValue } from "./slice";

const App = () => {
  console.log = console.warn = console.error = () => {};
  const dispatch = useDispatch();
  const datos = useSelector(getValue);
  const theme = null;

  useEffect(() => {
    async function loadUser() {
      const user = await getUser(sessionStorage.getItem("user"));
      dispatch(change(user.datos));
      createTheme({
        palette: {
          primary: {
            main: datos.primaryColor,
          },
          secondary: {
            main: datos.secondaryColor,
          },
        },
      });
    }

    if (window.sessionStorage.getItem("token") && !datos.usuario) loadUser();
  });

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/tickets" element={<Tickets />} />
          <Route exact path="/knowledge" element={<Knowledge />} />
          <Route exact path="/catalogue" element={<Catalogo />} />
          <Route exact path="/learning" element={<Learning />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/spv" element={<SPV />} />
        </Routes>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
