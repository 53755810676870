import axios from 'axios';
import '../config';


export const loginUser = async (credentials) => {
  
  const config = {
    header : {
    'Access-Control-Allow-Origin': '*'
    }
  }

    return await axios.get(global.ruta+"/users/loginUser", {params: credentials}, config)
    .then(response => {
      
        sessionStorage.setItem('token',  response.data.token);
        sessionStorage.setItem('user',  response.data.datos.usuario);

        return response.data
    })
    .catch(err => {
      console.log(err)
      return err;
    })
      
  }

  export const getUser = async (user) => {
  
    const config = {
      header : {
      'Access-Control-Allow-Origin': '*'
      }
    }
  
      return await axios.get(global.ruta+"/users/"+user, config)
      .then(response => {
       
          return response.data
      })
      .catch(err => {
        console.log(err)
      })
        
    }