import AddIcon from "@mui/icons-material/Add";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import TicketForm from "../../components/ticket/ticketForm/ticket-form.component";
import TicketList from "../../components/ticket/ticketList/ticket-list.component";
import TicketRelated from "../../components/ticket/ticketRelated/ticket-related.component";
import Title from "../../components/title/title.component";
import {
  obtenerTicketsCliente,
  obtenerToken,
} from "../../services/tickets.service";
import { isBrowser } from "react-device-detect";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { getValue } from "../../slice";
import "./tickets.container.scss";
import { getText } from "../../lang/language";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Tickets = () => {
  const datos = useSelector(getValue);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState(null);
  const [idTicket, setTicket] = useState();

  useEffect(() => {
    if (datos.nombreUser) {
      global.fecha_token = new Date().toLocaleDateString();
      obtenerToken().then((res) => {
        global.token = res;
        obtenerTicketsCliente(datos.nombreUser).then((tk) => setData(tk));
      });
    }
  }, [datos]);

  const handleTicket = (ticket) => {
    setTicket(ticket);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!sessionStorage.getItem("token")) return <Navigate to="/login" replace />;

  return (
    <>
      <Header page="/tickets" />
      <Title title={getText("incidents")} />
      <div
        style={{
          margin: "0 auto",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: isMobile ? "100%" : "70%",
          }}
        >
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="ticket tab"
              variant="fullWidth"
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: datos.primaryColor,
                },
              }}
            >
              <Tab
                icon={<AddIcon />}
                label={getText("new_incidence")}
                {...a11yProps(0)}
              />
              {datos?.permisos === 1 ? (
                <Tab
                  icon={<FormatListBulletedIcon />}
                  label={getText("incidents")}
                  {...a11yProps(1)}
                />
              ) : null}
              <Tab
                icon={<StickyNote2Icon />}
                label={getText("related_incidents")}
                {...a11yProps(datos?.permisos === 1 ? 2 : 1)}
              />
            </Tabs>
          </div>
        </Box>
        {datos?.permisos === 1 ? (
          <TabPanel
            value={value}
            index={1}
            style={{ width: isBrowser ? "80%" : "100%" }}
          >
            <TicketList setTicket={handleTicket} data={data} />
          </TabPanel>
        ) : null}
        <TabPanel
          value={value}
          index={datos?.permisos === 1 ? 2 : 1}
          style={{ width: "80%" }}
          className="tab-pan"
        >
          <TicketRelated />
        </TabPanel>
        <TabPanel
          value={value}
          index={0}
          style={{ width: "80%" }}
          className="tab-pan"
        >
          <TicketForm />
        </TabPanel>
      </div>
      {data ? <Footer /> : null}
    </>
  );
};

export default Tickets;
