import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getValue } from "../../../src/slice";
import SuccessLogo from "../../assets/images/logos_archivos/success.png";
import ErrorLogo from "../../assets/images/logos_archivos/error.png";
import "./modal-success-error.scss";
import { getText } from "../../lang/language";

const ModalSuccessError = (props) => {
  const datos = useSelector(getValue);

  const styles = {
    style: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      minHeight: "300px",
      width: "250px",
      height: "auto",
      border: "1px solid ##E6E6E6",
      boxShadow: 24,
      borderRadius: "20px",
      p: 4,
      paddingTop: 1,
    },
  };

  return (
    <Modal
      open={props?.open}
      onClose={props?.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.style}>
        <div
          className="close"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "-0.5rem 0 0 0",
          }}
        >
          <IconButton
            onClick={props?.close}
            sx={{ xs: 1, md: 3 }}
            className="close"
            style={{
              margin: "0 -1.9rem 0 0",
            }}
          >
            <CloseIcon fontSize="small" style={{ color: datos.primaryColor }} />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {props?.type === "success" ? (
            <>
              <h2
                style={{
                  fontSize: "32px",
                  color: "#4D4D4D",
                  margin: "0 0 10px 0",
                }}
              >
                {getText("sent")}
              </h2>
              <p className="subtitle" style={{ textAlign: "center" }}>
                {props.text}
              </p>
              <img
                src={SuccessLogo}
                alt="success"
                height={"200px"}
                width={"auto"}
              />{" "}
            </>
          ) : null}
          {props?.type === "error" ? (
            <>
              <h2
                style={{
                  fontSize: "32px",
                  color: "#4D4D4D",
                  margin: "0 0 10px 0",
                }}
              >
                ¡Ups!
              </h2>
              <p className="subtitle" style={{ textAlign: "center" }}>
                {props.text}
              </p>
              <img
                src={ErrorLogo}
                alt="error"
                height={"150px"}
                width={"auto"}
              />{" "}
            </>
          ) : null}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalSuccessError;
