import AssignmentIcon from "@mui/icons-material/Assignment";
import EmailIcon from "@mui/icons-material/Email";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import ReactLoading from "react-loading";
import { getText } from "../../../lang/language";
//Estado redux
import { useSelector } from "react-redux";
import { enviarMensaje, obtenerToken } from "../../../services/tickets.service";
import { getValue } from "../../../slice";
import ModalSuccessError from "../../modalSuccesError/modal-success-error";

const TicketList = (props) => {
  const [countCard, setCount] = useState(9);

  const datos = useSelector(getValue);
  const [filter, setFilter] = useState("");

  const [message, setMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [textError, setTextError] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [ticket, setTicket] = useState();
  const [openSend, setOpenSend] = useState(false);

  const handleSendMessage = async () => {
    setLoadingSend(true);
    global.token = await obtenerToken();
    global.fecha_token = new Date().toLocaleDateString();
    if (message && message !== "") {
      const res = await enviarMensaje(
        message,
        ticket["Id"],
        ticket["SuppliedEmail"],
        datos.nombre
      );
      if (res.status === 200) {
        setOpenSend(true);
      }
      handleClose();
    } else {
      setTextError(true);
    }
    setLoadingSend(false);
  };

  const handleCloseSuccessError = () => {
    setOpenSend(false);
  };

  const handleClose = () => {
    setTextError(false);
    setMessage(null);
    setOpen(false);
    setTicket(null);
  };

  const styles = {
    grupo: {
      mimHeight: "200px",
      color: "black",
      elevation: "20",
      borderRadius: "10px",
    },
    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      border: "1px solid ##E6E6E6",
      boxShadow: 24,
      borderRadius: "20px",
      p: 4,
    },
  };

  const handleOpen = (ticket) => {
    setOpen(true);
    setTicket(ticket);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          width: "90%",
        }}
      >
        <TextField
          placeholder={getText("filter_by_reference")}
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          id="standard-bare"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <FilterAltIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: { fontFamily: "Avenir-Next" },
          }}
          sx={{
            width: isMobile ? "100%" : "30%",
            backgroundColor: "white",
            borderRadius: "20px !important",
            border: "none",
            float: "left",
          }}
        />
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            margin: "20px 50px 50px 50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={{ xs: 1, md: 5 }}>
            {props.data ? (
              props.data?.records
                ?.filter(
                  (f) => f["CaseNumber"].includes(filter) || filter === ""
                )
                .map((row, key) => {
                  if (key < countCard) {
                    return (
                      <Grid item xs={12} md={6} lg={4}>
                        <Card elevation={5} sx={styles.grupo}>
                          <CardHeader
                            avatar={
                              <Avatar
                                sx={{ bgcolor: datos.primaryColor }}
                                variant="rounded"
                              >
                                <AssignmentIcon />
                              </Avatar>
                            }
                            title={getText("incident_number")}
                            subheader={row["CaseNumber"]
                              .replace(0, "")
                              .replace(0, "")}
                            subheaderTypographyProps={{
                              fontSize: "25px !important",
                            }}
                            sx={{ textAlign: "left" }}
                          />
                          <CardContent
                            style={{
                              borderTop: "1px solid" + datos.primaryColor,
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              sx={{ mb: 0.5 }}
                              variant="h8"
                              className="family-title"
                            >
                              {getText("name")}
                            </Typography>

                            <Typography
                              color="text.secondary"
                              sx={{ mb: 1.5 }}
                              component="div"
                              className="family-text"
                            >
                              {row["SuppliedName"] ?? "Sin nombre"}
                            </Typography>
                            <Typography
                              sx={{ mb: 0.5 }}
                              variant="h8"
                              className="family-title"
                            >
                              {getText("state")}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              sx={{ mb: 1.5 }}
                              component="div"
                              className="family-text"
                            >
                              {row["Status"]}
                            </Typography>
                            <Typography
                              sx={{ mb: 0.5 }}
                              variant="h8"
                              className="family-title"
                            >
                              {getText("management_to_carry_out")}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              sx={{ mb: 1.5 }}
                              component="div"
                              className="family-text"
                            >
                              {row["CB_Gestion_del_ticket__c"]}
                            </Typography>
                            <Typography
                              sx={{ mb: 0.5 }}
                              variant="h8"
                              className="family-title"
                            >
                              {getText("email")}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              sx={{ mb: 1.5 }}
                              component="div"
                              className="family-text"
                            >
                              {row["SuppliedEmail"]}
                            </Typography>
                            <Typography
                              sx={{ mb: 0.5 }}
                              variant="h8"
                              className="family-title"
                            >
                              {getText("last_update_date")}
                            </Typography>
                            <Typography
                              color="text.secondary"
                              sx={{ mb: 1.5 }}
                              component="div"
                              className="family-text"
                            >
                              {row["LastModifiedDate"]
                                .replace(".000+0000", "")
                                .replace("T", " ")}
                            </Typography>
                          </CardContent>
                          <CardActions
                            style={{
                              backgroundColor: datos.primaryColor,
                            }}
                          >
                            <Button
                              size="large"
                              style={{ color: "white", margin: "0 auto" }}
                              onClick={() => handleOpen(row)}
                            >
                              <EmailIcon style={{ marginRight: "10px" }} />
                              {getText("send_message")}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  } else return null;
                })
            ) : (
              <div style={{ width: "60 px", margin: "30px auto" }}>
                <ReactLoading type={"bars"} color={datos.primaryColor} />
              </div>
            )}
          </Grid>
        </Box>
        {countCard <
        props.data?.records?.filter(
          (f) => f["CaseNumber"].includes(filter) || filter === ""
        ).length ? (
          <Button
            variant="contained"
            style={{ backgroundColor: datos.primaryColor }}
            onClick={() => setCount(countCard + 9)}
          >
            {getText("load_more")}
          </Button>
        ) : null}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.style}>
          <div>
            <h3>{getText("do_you_have_any_questions_about_this_ticket")}</h3>
            <p className="subtitle">
              {getText(
                "write_to_us_explaining_the_reason_for_the_query_and_we_will_contact_the_client_as_soon_as_possible"
              )}
            </p>
            <form>
              <TextField
                required
                id="outlined-basic"
                name="mensaje"
                variant="outlined"
                multiline={true}
                minRows={6}
                onChange={(e) => setMessage(e.target.value)}
                style={{ margin: "20px 0", width: "100%" }}
              />
              {textError ? (
                <p className="redColor" style={{ margin: "0 0 30px 0" }}>
                  {getText("fill_in_the_body_of_the_message")}
                </p>
              ) : null}

              <Button
                variant="outlined"
                startIcon={loadingSend ? null : <ForwardToInboxIcon />}
                style={{
                  color: datos.primaryColor,
                  borderColor: datos.primaryColor,
                  fontFamily: "Avenir-Next",
                  width: "130px",
                  height: "60px",
                  float: "right",
                }}
                onClick={handleSendMessage}
              >
                {loadingSend ? (
                  <ReactLoading type={"bars"} color={datos.primaryColor} />
                ) : (
                  <span>{getText("send")}</span>
                )}
              </Button>
            </form>
          </div>
        </Box>
      </Modal>
      <ModalSuccessError
        open={openSend}
        close={handleCloseSuccessError}
        type="success"
        text={getText("we_will_contact_with_the_customer_shortly")}
      />
    </div>
  );
};

export default TicketList;
