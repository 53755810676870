import { Button, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import Fondo from "../../assets/images/login/loginBackground.jpg";
import { loginUser } from "../../services/usuarios.service";
import { change } from "../../slice";
import LogoIntex from "../../assets/images/intex_primaryColor.png";
import "./login.scss";

const Login = () => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setLoading(true);
    const datos = await loginUser({
      username,
      password,
    });

    if (datos.datos) {
      dispatch(change(datos.datos));
      navigate("/", { replace: true, redirect: true });
    } else {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div className="fondo" style={{ backgroundImage: `url(` + Fondo + `)` }}>
      <Paper elevation={20} className="login">
        <img
          src={LogoIntex}
          alt=""
          style={{ margin: "40px 0 0 0", width: "50%", height: "auto" }}
        />
        <div className="loginCard">
          <TextField
            required
            id="outlined-basic"
            label="User"
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
            onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            style={{ margin: "15px", width: "80%" }}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
          />
          <TextField
            required
            type="password"
            id="outlined-basic"
            label="Password"
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            style={{ margin: "15px", width: "80%" }}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
          />
          <Button
            onClick={handleSubmit}
            startIcon={loading ? null : <ForwardToInboxIcon />}
            variant="contained"
            style={{ backgroundColor: "#318daa", padding: "8px 16px" }}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type={"bars"} color="#fff" />
            ) : (
              <span style={{ margin: "0" }}>LOG IN </span>
            )}
          </Button>
          {error ? (
            <p className="redColor" style={{ margin: "5px 0" }}>
              Incorrect user or password.
            </p>
          ) : null}
        </div>
      </Paper>
    </div>
  );
};

export default Login;
