import { Card, CardMedia, Grid } from "@material-ui/core";
import { CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { Navigate } from "react-router-dom";
import PdfImg from "../../assets/images/logos_archivos/pdf.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ModalMedia from "../../components/modalMedia/modal-media.component";
import Title from "../../components/title/title.component";
import { getText } from "../../lang/language";
import { getSPVSheets } from "../../services/spv.service";
import { getValue } from "../../slice";
import "./spv.container.scss";

const SPV = () => {
  const datos = useSelector(getValue);
  const [sheets, setSheets] = useState([]);
  const [openModalMedia, setOpenModalMedia] = useState(false);
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    getSPVSheets().then((spvs) => setSheets(spvs));
  }, []);

  if (!sessionStorage.getItem("token")) return <Navigate to="/login" replace />;

  return (
    <>
      <Header page="/spv" />
      <Title title={getText("spv")} className="tituloKnow" />
      <Zoom>
        <h1
          style={{
            fontSize: isMobile ? "20px" : "30px",
            margin: "50px 0 20px 0",
            color: datos.primaryColor,
          }}
        >
          {getText("manage_an_incident_or_the_guarantee_of_your_product")}
        </h1>

        <div
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 80px 0",
          }}
        >
          {sheets && (
            <>
              <Grid
                container
                spacing={3}
                style={{
                  justifyContent: "center",
                }}
              >
                {sheets.map((sheet, key) => {
                  return (
                    <Grid item xs={12} md={3} key={key}>
                      <Card
                        style={{
                          zIndex: 2,
                          height: "320px",
                        }}
                        className="knowCard"
                      >
                        <CardContent
                          style={{
                            backgroundImage: `linear-gradient(0deg, rgb(180, 180, 180, 0.1), rgb(180, 180, 180, 0.1)),url(${
                              "https://api.clientes.intexcorp.es/public/spv" +
                              sheet["imagen" + datos.locale]
                            })`,
                            backgroundPosition: "top",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            color: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            padding: 0,
                          }}
                        />
                      </Card>
                      <div style={{ marginTop: "-1.8rem", zIndex: 1 }}>
                        <Grid
                          container
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            justifyContent: "center",
                            width: "240px",
                          }}
                        >
                          {sheet["instrucciones" + datos.locale] && (
                            <Grid item xs={3} className="logoKnow">
                              <div
                                className="fondoLogo"
                                onClick={() => {
                                  setPdf(
                                    "https://api.clientes.intexcorp.es/public/spv" +
                                      sheet["instrucciones" + datos.locale]
                                  );
                                  setOpenModalMedia(true);
                                }}
                              >
                                <CardMedia
                                  className="logoItem"
                                  component="img"
                                  image={PdfImg}
                                  alt="pdf"
                                  sx={{
                                    width: "32px !important",
                                  }}
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </div>
        <ModalMedia
          pdf={pdf}
          open={openModalMedia}
          close={() => {
            setOpenModalMedia(false);
            setPdf(null);
          }}
        />
      </Zoom>
      <Footer />
    </>
  );
};

export default SPV;
