import { ErrorMessage } from "@hookform/error-message";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Autocomplete,
  Button,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import esLocale from "date-fns/locale/es";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { getText } from "../../../lang/language";
import {
  getArticulos,
  getCategorias,
  getGrupos,
} from "../../../services/catalogo.service";
import {
  addImagen,
  crearTicket,
  obtenerToken,
} from "../../../services/tickets.service";
import { getValue } from "../../../slice";
import ModalSuccessError from "../../modalSuccesError/modal-success-error";
import "./ticket-form.component.scss";

const TicketForm = (props) => {
  const [grupos, setGrupos] = useState();
  const [categorias, setCategorias] = useState();
  const [productos, setProductos] = useState();
  const datos = useSelector(getValue);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [fecha_compra, setFecha] = useState(new Date());
  const [factura, setFactura] = useState(null);
  const [imagen, setImagen] = useState(null);
  const [defecto, setDefecto] = useState(null);
  const [error, setError] = useState({
    facturaError: false,
    imagenError: false,
    defectoError: false,
  });
  const [grupo, setGrupo] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [producto, setProducto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [leido, setLeido] = useState(false);
  const [errorLeido, setErrorLeido] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    if (datos.idEmpresa) {
      getGrupos(datos.idEmpresa).then((groups) => {
        setGrupos(
          groups?.reduce((groupsMap, group) => {
            groupsMap = [
              ...groupsMap,
              { label: group["nombre" + datos.locale], id: group.idGrupo },
            ];
            return groupsMap;
          }, [])
        );
      });
    }
  }, [datos]);

  const handleCloseSuccessError = () => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const handleCategorias = (labelGrupo) => {
    const chooseGroup = grupos.find((group) => group.label === labelGrupo);
    const getCat = async () => {
      const cats = await getCategorias(chooseGroup?.id);
      setCategorias(
        cats?.reduce((categMap, cat) => {
          categMap = [
            ...categMap,
            { label: cat["nombre" + datos.locale], id: cat.idCategoria },
          ];
          return categMap;
        }, [])
      );
    };
    setGrupo(chooseGroup);
    setCategoria(null);
    setProducto(null);
    getCat();
    setProductos(null);
  };

  const handleProductos = (labelCateg) => {
    const chooseCateg = categorias.find((categ) => categ.label === labelCateg);
    const getProd = async () => {
      const arts = await getArticulos(chooseCateg?.id, datos.idUsuario);
      setProductos(
        arts?.reduce((artMap, art) => {
          artMap = [
            ...artMap,
            {
              label: art["nombre" + datos.locale],
              id: art.idProducto,
              imagen: art.imagen,
            },
          ];
          return artMap;
        }, [])
      );
    };
    setCategoria(chooseCateg);
    setProducto(null);
    setProductos(null);
    getProd();
  };

  const changeProducto = (labelProd) => {
    setProducto(productos.find((prod) => prod.label === labelProd));
  };

  const handleSend = async (data) => {
    console.log({ ...data, fecha_compra: fecha_compra.toLocaleDateString() });
    setLoading(true);
    if (leido) {
      if (factura && imagen && defecto) {
        global.token = await obtenerToken();
        global.fecha_token = new Date().toLocaleDateString();
        const res = await crearTicket(
          {
            ...data,
            fecha_compra:
              fecha_compra.getFullYear() +
              "-" +
              (fecha_compra.getMonth() + 1) +
              "-" +
              fecha_compra.getDate(),
          },
          datos.idSalesforce,
          producto.id
        );

        if (res.status === 200) {
          setOpenSuccess(true);
          setErrorLeido(false);
        } else {
          setOpenError(true);
          setLoading(false);
        }
        if (res.data.success) {
          zipFiles(res.data.id);
        }
      } else {
        setError({
          facturaError: !factura & true,
          imagenError: !imagen & true,
          defectoError: !defecto & true,
        });
      }
    } else {
      setErrorLeido(true);
    }
    setLoading(false);
  };

  const zipFiles = async (idTicket) => {
    const factura64 = await convertBase64(factura);
    const imagen64 = await convertBase64(imagen);
    const defecto64 = await convertBase64(defecto);
    await addImagen(factura.name, factura64, idTicket);
    await addImagen(imagen.name, imagen64, idTicket);
    await addImagen(defecto.name, defecto64, idTicket);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <form onSubmit={handleSubmit(handleSend)} noValidate autoComplete="off">
      <h4 className="subtitle">{getText("personal_data")}</h4>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("name") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("nombre", {
              required: {
                value: true,
                message: getText("the_name_is_necessary"),
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="nombre"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("first_surname") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("primerApellido", {
              required: {
                value: true,
                message: getText("the_first_last_name_is_required"),
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="primerApellido"
            className="errorMessage"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("second_surname")}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("segundoApellido")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label="DNI/NIE*"
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("dni", {
              required: getText("the_DNI_NIE_is_necessary"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="dni"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("delivery_address") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("direccion", {
              required: getText("the_delivery_address_is_required"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="direccion"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("postal_code") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("codigoPostal", {
              required: getText("the_postal_code_is_required"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="codigoPostal"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("country") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("pais", {
              required: getText("the_country_is_required"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="pais"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("province") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("provincia", {
              required: getText("the_province_is_required"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="pais"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("city") + "/" + getText("municipality") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("ciudad", {
              required: getText("the_city_is_required"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="ciudad"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("phone_number") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("telefono", {
              required: getText("the_phone_number_is_required"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="telefono"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            variant="outlined"
            className="textField"
            label={getText("email") + "*"}
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("email", {
              required: getText("the_email_is_required"),
            })}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
      </Grid>
      <h4 className="subtitle" style={{ margin: "1rem 0" }}>
        {getText("article_data")}
      </h4>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} sm={12} md={4}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                className="textField"
                id="modelo"
                options={grupos ?? []}
                value={grupo}
                getOptionLabel={(option) => option.label}
                name="modelo"
                onChange={(event) => {
                  handleCategorias(event.target.textContent);
                }}
                ListboxProps={{ style: { fontFamily: "Avenir-Next" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getText("model") + "*"}
                    {...register("modelo", {
                      required: getText("the_model_is_required"),
                    })}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="modelo"
                render={({ message }) => <p className="redColor">{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                className="textField"
                id="grupo"
                options={categorias ?? []}
                value={categoria}
                getOptionLabel={(option) => option.label}
                name="grupo"
                onChange={(event) => {
                  handleProductos(event.target.textContent);
                }}
                ListboxProps={{ style: { fontFamily: "Avenir-Next" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getText("group") + "*"}
                    {...register("grupo", {
                      required: getText("the_group_is_required"),
                    })}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="grupo"
                render={({ message }) => <p className="redColor">{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                className="textField"
                id="referencia"
                options={productos ?? []}
                value={producto}
                ListboxProps={{ style: { fontFamily: "Avenir-Next" } }}
                getOptionLabel={(option) => option.label}
                name="referencia"
                onChange={(event) => {
                  changeProducto(event.target.textContent);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getText("reference") + "*"}
                    {...register("referencia", {
                      required: getText("the_reference_is_required"),
                    })}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="referencia"
                render={({ message }) => <p className="redColor">{message}</p>}
              />
            </Grid>
          </Grid>
        </Grid>{" "}
        {producto ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className="imgResp"
            sx={{
              maxHeight: "220px",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <CardMedia
              component="img"
              image={
                "https://www.distria.com/images/productos/large/" +
                producto.id +
                "_1.jpg"
              }
              alt="articulo"
              style={{
                width: "auto",
                maxHeight: "100%",
                border: "2px solid" + datos.primaryColor,
                borderRadius: "15px",
              }}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={producto ? 4 : 8}>
          <TextField
            multiline
            rows={7}
            variant="outlined"
            placeholder="Descripción del problema"
            label="Descripción del problema*"
            inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            {...register("descripcion", {
              required: "La descripción del problema es necesaria.",
            })}
            style={{ width: "100%" }}
          />
          <ErrorMessage
            errors={errors}
            name="descripcion"
            render={({ message }) => <p className="redColor">{message}</p>}
          />
        </Grid>
      </Grid>
      <h4 className="subtitle" style={{ margin: "1rem 0" }}>
        {getText("purchase_data")}
      </h4>
      <Grid container spacing={{ xs: 1, md: 2 }}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider
            locale={esLocale}
            dateAdapter={AdapterDateFns}
            className="w-100"
          >
            <DatePicker
              label={getText("purchase_date")}
              value={fecha_compra}
              name="fecha"
              inputProps={{ style: { fontFamily: "Avenir-Next" } }}
              renderInput={(params) => (
                <TextField className="w-100" {...params} />
              )}
              onChange={(event) => {
                setFecha(event);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
          <Button
            variant="contained"
            component="label"
            sx={{
              color: "#fff",
              backgroundColor: datos.primaryColor,
              border: "1px solid",
              boxShadow: "none",
              fontFamily: "Avenir-Next",
              borderColor: datos.primaryColor,
              "&:hover": {
                backgroundColor: datos.primaryColor,
                opacity: "0.9",
                boxShadow: "none",
              },
            }}
            disableRipple={true}
          >
            {getText("select_file")}
            <input
              type="file"
              name="factura"
              onChange={(event) => {
                setFactura(event.target.files[0]);
                setError({ ...error, facturaError: false });
              }}
              hidden
            />
          </Button>
          <span
            style={{
              paddingLeft: 10,
              color: "grey",
              fontFamily: "Avenir-Next",
            }}
          >
            {factura ? factura.name : getText("add_the_purchase_invoice")}
          </span>
          {error.facturaError ? (
            <p className="redColor" style={{ margin: "5px 0" }}>
              {getText("attach_the_3_files_to_be_able_to_create_the_ticket")}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
          <Button
            variant="contained"
            component="label"
            sx={{
              color: "#fff",
              backgroundColor: datos.primaryColor,
              border: "1px solid",
              boxShadow: "none",
              fontFamily: "Avenir-Next",
              borderColor: datos.primaryColor,
              "&:hover": {
                backgroundColor: datos.primaryColor,
                opacity: "0.9",
                boxShadow: "none",
              },
            }}
          >
            {getText("select_file")}
            <input
              type="file"
              name="imagen"
              onChange={(event) => {
                setImagen(event.target.files[0]);
                setError({ ...error, imagenError: false });
              }}
              hidden
            />
          </Button>
          <span
            style={{
              paddingLeft: 10,
              color: "grey",
              fontFamily: "Avenir-Next",
            }}
          >
            {imagen ? imagen.name : getText("add_a_product_image")}
          </span>
          {error.imagenError ? (
            <p className="redColor" style={{ margin: "5px 0" }}>
              {getText("attach_the_3_files_to_be_able_to_create_the_ticket")}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: "left" }}>
          <Button
            variant="contained"
            component="label"
            sx={{
              color: "#fff",
              backgroundColor: datos.primaryColor,
              border: "1px solid",
              boxShadow: "none",
              fontFamily: "Avenir-Next",
              borderColor: datos.primaryColor,
              "&:hover": {
                backgroundColor: datos.primaryColor,
                opacity: "0.9",
                boxShadow: "none",
              },
            }}
          >
            {getText("select_file")}
            <input
              type="file"
              name="defecto"
              onChange={(event) => {
                setDefecto(event.target.files[0]);
                setError({ ...error, defectoError: false });
              }}
              hidden
            />
          </Button>
          <span
            style={{
              paddingLeft: 10,
              color: "grey",
              fontFamily: "Avenir-Next",
            }}
          >
            {defecto ? defecto.name : getText("add_a_defect_image")}
          </span>
          {error.defectoError ? (
            <p className="redColor" style={{ margin: "5px 0" }}>
              {getText("attach_the_3_files_to_be_able_to_create_the_ticket")}
            </p>
          ) : null}
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: "#318daa" }}
              value={leido}
              onChange={(e, v) => {
                setLeido(v);
                setErrorLeido(false);
              }}
            />
          }
          label={
            <>
              <p>
                {getText("I_have_read_and_accept_the")}
                <a
                  href="https://www.intexserviceiberia.com/politica-privacidad"
                  style={{ textDecoration: "none", color: "#318daa" }}
                >
                  {" "}
                  {getText("privacy_policy")}
                </a>
                .<strong style={{ color: "red" }}>{errorLeido && "*"} </strong>
              </p>
            </>
          }
        />
      </div>
      <Button
        type="submit"
        variant="outlined"
        startIcon={loading ? null : <ForwardToInboxIcon />}
        style={{
          color: datos.primaryColor,
          borderColor: datos.primaryColor,
          fontFamily: "Avenir-Next",
          width: "130px",
          height: "60px",
        }}
        disabled={loading}
      >
        {loading ? (
          <ReactLoading type={"bars"} color={datos.primaryColor} />
        ) : (
          <span>{getText("send")}</span>
        )}
      </Button>

      <ModalSuccessError
        open={openSuccess}
        close={handleCloseSuccessError}
        type="success"
        text={getText(
          "shortly_we_will_manage_the_incident_and_we_will_give_you_an_answer"
        )}
      />

      <ModalSuccessError
        open={openError}
        close={handleCloseSuccessError}
        type="error"
        text={getText("it_seems_something_went_wrong_please_try_again")}
      />
    </form>
  );
};

export default TicketForm;
