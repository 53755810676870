import { getText } from "../../lang/language";

const MailKnowledge = (props) => {
  return (
    <table style={{ align: "center", width: "700px" }}>
      <tr>
        <td
          style={{
            borderBottom: "1px solid #d6d6d6",
            textAlign: "center",
          }}
        >
          <img
            src={
              "https://clientes.intexcorp.es/images/imagotipos/intex/intex_corp.png"
            }
            alt="INTEX"
            style={{ padding: "40px 0 15px 0" }}
          ></img>
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: "center", padding: "10px 20px" }}>
          <p style={{ fontSize: "20px" }}>
            {getText(
              "below_we_attach_a_pdf_with_all_the_information_related_to"
            )}
            <b>{props.nombre}</b>.
          </p>
        </td>
      </tr>
      {props?.video ? (
        <>
          <tr>
            <td style={{ textAlign: "center", padding: "10px 20px" }}>
              <p style={{ fontSize: "20px" }}>
                {getText("we_thought_this_related_video_might_help_you")}:
              </p>
            </td>
          </tr>
          <tr
            style={{
              height: "10px",
              margin: "20px 0",
            }}
          >
            <td style={{ textAlign: "center" }}>
              <table
                style={{
                  align: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <tr style={{ paddingTop: "0" }}>
                  <td
                    style={{
                      borderRadius: "20px",
                      textAlign: "center",
                    }}
                    bgcolor="#80bcd1"
                  >
                    <a
                      href={props.video}
                      style={{
                        width: "80px",
                        padding: "8px 12px",
                        border: "1px solid #80bcd1",
                        borderRadius: "20px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontSize: "20px",
                        color: "#ffffff",
                        textDecoration: "none",
                        fontWeight: "bold",
                        display: "inline-block",
                      }}
                    >
                      {getText("watch_video")}
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </>
      ) : null}
      <tr>
        <td
          style={{
            margin: "50px 0 0 0",
            backgroundColor: "rgb(49, 141, 170)",
            padding: "10px auto",
            textAlign: "center",
          }}
        >
          <p style={{ color: "white" }}>
            {"©2023 INTEX | " + getText("all_rights_reserved")}
          </p>
        </td>
      </tr>
    </table>
  );
};

export default MailKnowledge;
