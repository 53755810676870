import { Search } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from "@mui/material";
import Link from "@mui/material/Link";
import React from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Fade, Zoom } from "react-reveal";
import { Navigate, useNavigate } from "react-router-dom";
import KnowledgeLogo from "../../assets/images/landing/baseConocimientoLogo.png";
import Blue from "../../assets/images/landing/blue.png";
import CatalogoLogo from "../../assets/images/landing/catalogoLogo.png";
import FormacionLogo from "../../assets/images/landing/learningLogo.png";
import Red from "../../assets/images/landing/red.png";
import TicketsLogo from "../../assets/images/landing/ticketsLogo.png";
import Yellow from "../../assets/images/landing/yellow.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { getText } from "../../lang/language";
import { getValue } from "../../slice";
import "./landing.container.scss";

const Landing = () => {
  const datos = useSelector(getValue);
  const navigate = useNavigate();
  const styles = {
    container: {
      backgroundImage: `linear-gradient(0deg, rgba(27, 27, 27, 0.3), rgba(27, 27, 27, 0.3)),url(${datos.cabecera})`,
      backgroundPosition: "center top",
      backgroundSize: "2350px",
      backgroundRepeat: "no-repeat",
      width: "100%",
      minHeight: "650px",
      height: "700px",
    },
  };

  const handleSend = (data) => {
    if (data.search)
      navigate("/knowledge?search=" + data.search, {
        replace: true,
        redirect: true,
      });
  };

  const { register, handleSubmit } = useForm();

  if (!sessionStorage.getItem("token")) return <Navigate to="/login" replace />;

  return (
    <>
      <Header page="/" />
      <div className="landcap" style={styles.container}>
        <div id="form-box">
          <Zoom cascade>
            <div>
              <h1
                style={{
                  fontSize: isMobile ? "45px" : "60px",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {getText("how_can_we_help_you")}
              </h1>
            </div>
          </Zoom>

          <form onSubmit={handleSubmit(handleSend)}>
            <TextField
              placeholder={getText("find_everything_you_need")}
              id="standard-bare"
              {...register("search")}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { fontFamily: "Avenir-Next" },
              }}
              sx={{
                width: isMobile ? "80%" : "50%",
                backgroundColor: "white",
                borderRadius: "10px !important",
                border: "none",
              }}
            />
          </form>
        </div>
      </div>
      <div className="land-tarjetas">
        <Grid
          container
          spacing={5}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={8} md={6} lg={3}>
            <Fade bottom>
              <Link href="/tickets" underline="none">
                <Card sx={{ height: "300px", borderRadius: "20px" }}>
                  <CardMedia
                    component="img"
                    image={datos.idEmpresa === 1 ? Blue : TicketsLogo}
                    alt="imagen"
                    sx={{
                      width: "120px !important",
                      margin: "0 auto",
                      paddingTop: "20px",
                    }}
                  />
                  <CardContent>
                    <h2> {getText("incidents", "Incidents")}</h2>
                    <p className="cardText">
                      {getText(
                        "manage_your_incidents_without_waiting_quickly_and_efficiently"
                      )}
                    </p>
                  </CardContent>
                </Card>
              </Link>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={3}>
            <Fade bottom>
              <Link href="/knowledge" underline="none">
                <Card sx={{ height: "300px", borderRadius: "20px" }}>
                  <CardMedia
                    component="img"
                    image={datos.idEmpresa === 1 ? Red : KnowledgeLogo}
                    alt="imagen"
                    sx={{
                      width: "120px !important",
                      margin: "0 auto",
                      paddingTop: "20px",
                    }}
                  />
                  <CardContent>
                    <h2> {getText("knowledge")}</h2>
                    <p className="cardText">
                      {getText(
                        "solve_your_doubts_about_the_products_and_their_operation"
                      )}
                    </p>
                  </CardContent>
                </Card>
              </Link>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={3}>
            <Fade bottom>
              <Link href="/catalogue" underline="none">
                <Card sx={{ height: "300px", borderRadius: "20px" }}>
                  <CardMedia
                    component="img"
                    image={datos.idEmpresa === 1 ? Yellow : CatalogoLogo}
                    alt="imagen"
                    sx={{
                      width: "120px !important",
                      margin: "0 auto",
                      paddingTop: "20px",
                    }}
                  />
                  <CardContent>
                    <h2> {getText("catalogue")}</h2>
                    <p className="cardText">
                      {getText(
                        "discover_all_the_available_items_that_you_can_have_in_your_store"
                      )}
                    </p>
                  </CardContent>
                </Card>
              </Link>
            </Fade>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={3}>
            <Fade bottom>
              <Link href="/learning" underline="none">
                <Card sx={{ height: "300px", borderRadius: "20px" }}>
                  <CardMedia
                    component="img"
                    image={datos.idEmpresa === 1 ? Yellow : FormacionLogo}
                    alt="imagen"
                    sx={{
                      width: "120px !important",
                      margin: "0 auto",
                      paddingTop: "20px",
                    }}
                  />
                  <CardContent>
                    <h2> {getText("formation")}</h2>
                    <p className="cardText">
                      {getText(
                        "access_the_most_complete_guide_to_get_to_know_all_our_products_in_depth"
                      )}
                    </p>
                  </CardContent>
                </Card>
              </Link>
            </Fade>
          </Grid>
        </Grid>
      </div>

      {/* <Banner /> */}
      <Footer />
    </>
  );
};

export default Landing;
