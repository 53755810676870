import axios from "axios";
import "../config";

export const getGrupos = async (empresa) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/knowledge/grupos/" + empresa, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getCategorias = async (grupo) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/knowledge/categorias/" + grupo, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getArticulos = async (categoria) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/knowledge/articulos/" + categoria, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllArticulosSearch = async (empresa, search, lang) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };
  return await axios
    .get(
      global.ruta +
        "/knowledge/articulos/empresa/" +
        empresa +
        "/search/" +
        search +
        "?lang=" +
        lang,
      config
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
