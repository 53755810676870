import { Zoom } from "react-reveal";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { getValue } from "../../slice";

import "./title.component.scss";

const Title = (props) => {
  const datos = useSelector(getValue);

  const styles = {
    container: {
      backgroundImage: `linear-gradient(0deg, rgba(27, 27, 27, 0.3), rgba(27, 27, 27, 0.3)),url(${datos.cabecera})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "40vh",
    },
  };

  return (
    <div className="landtitle" style={styles.container}>
      <div id="form-box2">
        <Zoom cascade>
          <div>
            <h1 style={{ fontSize: isMobile ? "40px" : "60px" }}>
              {props.title}
            </h1>
          </div>
        </Zoom>
      </div>
    </div>
  );
};

export default Title;
