import axios from "axios";
import "../config";

export const obtenerTicketsCliente = async (lugar_compra) => {
  const config = {
    params: { access_token: global.token, client: lugar_compra },
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };
  return await axios
    .get(global.ruta + "/tickets/ticketsCliente", config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerTicketsBusqueda = async (ticket, email) => {
  const config = {
    params: { access_token: global.token, ticket: ticket, email: email },
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/ticketsBusqueda", config)
    .then((response) => {
      return response.data.records;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const obtenerToken = async () => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/tickets/login", config)
    .then((response) => {
      return response.data.access_token;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const crearTicket = async (data, idSalesforce, producto) => {
  const config = {
    params: {
      access_token: global.token,
      cliente: idSalesforce,
      producto: producto,
    },
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: data,
  };

  return await axios
    .post(global.ruta + "/tickets/crearTicket", data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const addImagen = async (nombre, imagen, idTicket) => {
  const config = {
    params: { access_token: global.token, nombre: nombre, ticket: idTicket },
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: imagen,
  };

  return await axios
    .post(global.ruta + "/tickets/addImagen", { imagen: imagen }, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const enviarMensaje = async (mensaje, idSalesforce, email, tienda) => {
  const config = {
    params: { access_token: global.token },
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: mensaje,
  };

  return await axios
    .post(
      global.ruta + "/tickets/sendEmail",
      {
        ParentId: idSalesforce,
        TextBody: mensaje,
        FromAddress: email,
        FromName: tienda,
        Incoming: true,
      },
      config
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getDescripcionPorNombre = async (texto) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    params: {
      texto,
    },
  };

  return await axios
    .get(global.ruta + "/common/descripcion", config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
