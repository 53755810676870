import axios from "axios";
import "../config";

export const getConsultTypes = async (empresa) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/contact/consults/", config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createConsult = async (data, idSalesforce) => {
  const config = {
    params: {
      access_token: global.token,
      client: idSalesforce,
    },
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
    body: data,
  };

  return await axios
    .post(global.ruta + "/contact/create", data, config)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
