import axios from "axios";
import ReactDOMServer from "react-dom/server";
import MailKnowledge from "../components/mails/mail-knwoledge";
import "../config";

export const enviarEmail = async (email, video, pdf, nombre) => {
  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  const body = {
    email,
    pdf,
    nombre,
    html: ReactDOMServer.renderToString(<MailKnowledge nombre={nombre} video={video} />),
  };

  return await axios
    .post(global.ruta + "/common/sendEmail", body, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
