import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getText } from "../../lang/language";
import { getValue } from "../../slice";
import "./header.scss";

const Header = (props) => {
  const pages = [
    { page: getText("home"), link: "/" },
    { page: getText("incidents"), link: "/tickets" },
    { page: getText("knowledge"), link: "/knowledge" },
    { page: getText("catalogue"), link: "/catalogue" },
    { page: getText("formation"), link: "/learning" },
    { page: getText("contact"), link: "/contact" },
    { page: getText("spv"), link: "/spv" },
  ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [colorHeader, setHeader] = React.useState("header");

  const datos = useSelector(getValue);

  const styles = {
    header: {
      position: "fixed",
      backgroundColor: "transparent",
      color: "#fff ",
      boxShadow: "none",
      transform: "translateY(0)",
      transition: "transform 0.6s ease",
      fontFamily: "HelveticaNeue-BoldCondensed",
    },
    header2: {
      position: "fixed",
      backgroundColor: datos.primaryColor,
      color: "#fff",
      transform: "translateY(0)",
      transition: "transform 0.6s ease",

      fontFamily: "HelveticaNeue-BoldCondensed",
    },
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
  };

  const listenScrollEvent = (event) => {
    if (window.scrollY < 50) {
      return setHeader("header");
    } else if (window.scrollY > 50) {
      return setHeader("header2");
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <AppBar style={colorHeader === "header" ? styles.header : styles.header2}>
      <Container maxWidth="xl">
        <div
          style={{ display: "flex", justifyContent: "center" }}
          id="logoMarcaResp"
        >
          <NavLink
            to={"/"}
            style={{
              textUnderlineOffset: 4,
              textDecoration: "none",
              position: "absolute",
              top: 16,
              margin: "0 auto",
              zIndex: "2",
            }}
          >
            <img src={datos.logo} alt="logo" style={{ height: "30px" }} />
          </NavLink>
        </div>
        <Toolbar className="toolbarHeader" disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <NavLink
                  to={page.link}
                  style={{
                    color: "#4E4E4E",
                    fontSize: "12px",
                    textAlign: "left",
                    textDecoration: "none",
                  }}
                >
                  <MenuItem
                    key={page}
                    onClick={handleCloseNavMenu}
                    style={{
                      fontFamily: "HelveticaNeue-BoldCondensed",
                    }}
                  >
                    {page.page}
                  </MenuItem>
                </NavLink>
              ))}
              {/* {"/" === props.page && (
                <Link
                  to="bannerCorp"
                  spy={true}
                  smooth={true}
                  offset={-65}
                  style={{ fontFamily: "HelveticaNeue-BoldCondensed" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      padding: "0.5 0.5rem",
                      margin: "0 0.5rem",
                      backgroundColor: datos.primaryColor,
                      borderRadius: "30px",
                    }}
                    startIcon={<ShoppingCartIcon />}
                  >
                    {getText("spare_parts").toUpperCase()}
                  </Button>
                </Link>
              )} */}
            </Menu>
          </Box>

          <NavLink
            id="logoMarca"
            to={"/"}
            style={{
              textUnderlineOffset: 4,
              textDecoration: "none",
            }}
          >
            <img src={datos.logo} alt="logo" style={{ height: "30px" }} />
          </NavLink>

          <Box
            sx={{
              marginLeft: "20px",
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <NavLink
                to={page.link}
                style={{
                  textUnderlineOffset: 4,
                  textAlign: "left",
                  textDecoration: "none",
                }}
              >
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    color: "white",
                    textDecoration:
                      page.link === props.page ? "underline" : "none",
                    my: 2,
                    display: "block",
                    fontFamily: "HelveticaNeue-BoldCondensed",
                  }}
                >
                  {page.page}
                </Button>
              </NavLink>
            ))}
            {/* {"/" === props.page && (
              <Link
                to="bannerCorp"
                spy={true}
                smooth={true}
                offset={-65}
                style={{
                  fontFamily: "HelveticaNeue-BoldCondensed",
                  padding: "0 0.5rem 0 0.75rem",
                  margin: "1rem 0.5rem",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    backgroundColor:
                      colorHeader === "header" ? datos.primaryColor : "#EDEDED",
                    color:
                      colorHeader === "header" ? "#fff" : datos.primaryColor,
                    borderRadius: "30px",
                    fontFamily: "HelveticaNeue-BoldCondensed",
                  }}
                >
                  {getText("spare_parts").toUpperCase()}
                </Button>
              </Link>
            )} */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, width: "100px", height: "60px" }}
              >
                <Avatar
                  alt="Logo user"
                  src={datos.logotipo}
                  variant="square"
                  sx={{
                    p: 0,
                    width: "auto",
                    height: isMobile ? "35px" : "50px",
                    margin: isMobile ? "0 -30px 0 0" : null,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {!sessionStorage.getItem("token") ? (
                <MenuItem key="/login" onClick={handleCloseNavMenu}>
                  <NavLink
                    to="/login"
                    style={{ textDecoration: "none", color: "#646464" }}
                  >
                    <Typography textAlign="center">Login</Typography>
                  </NavLink>
                </MenuItem>
              ) : (
                <MenuItem key="/login" onClick={handleLogout}>
                  <NavLink
                    to="/login"
                    style={{ textDecoration: "none", color: "#646464" }}
                  >
                    <Typography
                      textAlign="center"
                      style={{ fontFamily: "HelveticaNeue-BoldCondensed" }}
                    >
                      {getText("logout")}
                    </Typography>
                  </NavLink>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
