import CloseIcon from "@mui/icons-material/Close";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ReactLoading from "react-loading";
import { Box, Button, IconButton, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getValue } from "../../../src/slice";
import { enviarEmail } from "../../services/common.service";
import "./modal-media.component.scss";
import { getText } from "../../lang/language";

const ModalMedia = (props) => {
  const [email, setEmail] = useState();
  const datos = useSelector(getValue);
  const [loading, setLoading] = useState(false);

  const handleSendEmail = async () => {
    setLoading(true);
    await enviarEmail(email, props.video, props.pdf, props.nombre).then(
      (res) => {
        if (res === "OK") {
          props?.result("success");
          props.close();
        } else {
          props?.result("error");
        }
      }
    );
    setLoading(false);
  };

  const styles = {
    style: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      minWidth: "290px",
      width:
        (props?.video || props?.pdf || props?.src) && !props?.nombre
          ? "65%"
          : null,
      height:
        props?.video && !props?.nombre
          ? "75%"
          : (props?.pdf || props?.src) && !props?.nombre
          ? "80%"
          : null,
      bgcolor: "background.paper",
      minHeight: "300px",
      border: "1px solid ##E6E6E6",
      boxShadow: 24,
      borderRadius: "20px",
      p: 4,
      paddingTop: 1,
    },
  };

  return (
    <Modal
      open={props?.open}
      onClose={props?.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.style}>
        <div
          className="close"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "-0.5rem 0 0 0",
          }}
        >
          <IconButton
            onClick={props?.close}
            sx={{ xs: 1, md: 3 }}
            className="close"
            style={{
              margin: "0 -1.9rem 0 0",
            }}
          >
            <CloseIcon fontSize="small" style={{ color: datos.primaryColor }} />
          </IconButton>
        </div>
        <div style={{ height: "100%" }}>
          {props?.pdf && !props.nombre ? (
            <iframe
              src={props?.pdf}
              style={{ width: "100%", height: "100%" }}
              frameBorder="0"
              title="prueba"
            />
          ) : props?.video && !props.nombre ? (
            <iframe
              width="100%"
              height="100%"
              src={props?.video}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : props?.src && !props.nombre ? (
            <iframe
              src={props?.src}
              style={{ width: "100%", height: "100%" }}
              frameBorder="0"
              title="prueba"
            />
          ) : (
            <div>
              <h3>
                {getText(
                  "do_you_want_to_share_this_document_with_your_customers"
                )}
              </h3>
              <p className="subtitle">
                {getText(
                  "enter_your_client_email_and_we_will_send_you_an_email_with_all_the_information"
                )}
              </p>
              <form>
                <TextField
                  required
                  id="outlined-basic"
                  label={getText("email")}
                  inputProps={{ style: { fontFamily: "Avenir-Next" } }}
                  variant="outlined"
                  style={{ margin: "30px 0", width: "100%" }}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />

                <Button
                  variant="outlined"
                  startIcon={loading ? null : <ForwardToInboxIcon />}
                  style={{
                    color: datos.primaryColor,
                    borderColor: datos.primaryColor,
                    fontFamily: "Avenir-Next",
                    width: "130px",
                    height: "60px",
                    float: "right",
                  }}
                  onClick={() => handleSendEmail()}
                  disabled={loading}
                >
                  {loading ? (
                    <ReactLoading type={"bars"} color={datos.primaryColor} />
                  ) : (
                    <span>{getText("send")} </span>
                  )}
                </Button>
              </form>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalMedia;
