import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ModalSuccessError from "../../components/modalSuccesError/modal-success-error";
import { useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { Navigate, useLocation } from "react-router-dom";
import { Link, scroller } from "react-scroll";
import PdfImg from "../../assets/images/logos_archivos/pdf.png";
import SendImg from "../../assets/images/logos_archivos/send.png";
import YouTubeImg from "../../assets/images/logos_archivos/youTube.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ModalMedia from "../../components/modalMedia/modal-media.component";
import Title from "../../components/title/title.component";
import VideoBanner from "../../components/videoBanner/video-banner.component";
import {
  getAllArticulosSearch,
  getArticulos,
  getCategorias,
  getGrupos,
} from "../../services/knowledge.service";
import { getValue } from "../../slice";
import "./knowledge.container.scss";
import { getText } from "../../lang/language";

const Knowledge = () => {
  const datos = useSelector(getValue);
  const [grupos, setGrupos] = useState();
  const [categorias, setCategorias] = useState();
  const [articulos, setArticulos] = useState();
  const [articulosBusqueda, setArticulosBusqueda] = useState();
  const [grupo, setGrupo] = useState();
  const [categoria, setCategoria] = useState();
  const [video, setVideo] = useState();
  const [pdf, setPdf] = useState();
  const [nombre, setNombre] = useState();
  const [open, setOpen] = React.useState(false);
  const url = useLocation().search;
  const search = new URLSearchParams(url)?.get("search");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [searchArtic, setSearchArtic] = useState();

  const handleOpenVideo = (value) => {
    setVideo(value);
    setOpen(true);
  };

  const handleOpenPdf = (value) => {
    setPdf(value);
    setOpen(true);
  };

  const handleOpenSend = (nombre, pdf, video) => {
    setOpen(true);
    setVideo(video);
    setPdf(pdf);
    setNombre(nombre);
  };

  const handleClose = () => {
    setOpen(false);
    setVideo(null);
    setPdf(null);
    setNombre(null);
  };

  const handleOpenSuccessError = (type) => {
    switch (type) {
      case "success":
        setOpenSuccess(true);
        break;
      case "error":
        setOpenError(true);
        break;
      default:
        break;
    }
  };

  const handleCloseSuccessError = () => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const scrollToSection = () => {
    scroller.scrollTo("artBus", {
      duration: 1500,
      delay: 2,
      smooth: true,
      offset: -90,
    });
  };

  useEffect(() => {
    if (search && !grupo) {
      const getArticsSearch = async () => {
        const artics = await getAllArticulosSearch(2, search, datos.locale);
        setArticulosBusqueda(artics);
      };
      if (!articulosBusqueda) getArticsSearch();
      scrollToSection();
    }
  });

  useEffect(() => {
    getGrupos(2).then((grs) => setGrupos(grs));
  }, []);

  const handleCategorias = (grupo) => {
    const getGrups = async () => {
      const grups = await getCategorias(grupo);
      setCategorias(grups);
    };
    setGrupo(grupo);
    setCategoria(null);
    getGrups();
    setArticulos(null);
    setSearchArtic(null);
  };

  const handleArticulos = (categoria) => {
    const getArts = async () => {
      const grups = await getArticulos(categoria);
      setArticulos(grups);
    };
    setCategoria(categoria);
    setSearchArtic(null);
    getArts();
  };

  if (!sessionStorage.getItem("token")) return <Navigate to="/login" replace />;

  return (
    <>
      <Header page="/knowledge" />
      <Title title={getText("knowledge")} className="tituloKnow" />
      <div
        id="grups"
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Zoom>
          <div>
            <h1
              style={{
                fontSize: isMobile ? "20px" : "30px",
                marginTop: "50px",
                color: datos.primaryColor,
              }}
            >
              {getText("select_the_category_you_want_to_consult")}
            </h1>
          </div>
        </Zoom>

        <div
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 20px 0",
          }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {grupos &&
              grupos.map((card, key) => {
                return (
                  <Grid item xs={12} md={4} lg={4}>
                    <Link to="categs" spy={true} smooth={true} offset={-90}>
                      <Card
                        onClick={() => handleCategorias(card.idGrupo)}
                        className="knowCard"
                        style={{
                          backgroundImage:
                            card.idGrupo === grupo
                              ? `linear-gradient(0deg, ${datos.primaryColor}70,  ${datos.primaryColor}70),url(${card.imagen})`
                              : `linear-gradient(0deg, rgba(27, 27, 27, 0.4), rgba(27, 27, 27, 0.4)),url(${card.imagen})`,
                        }}
                      >
                        <CardContent>
                          <h2>{card["nombre" + datos.locale]}</h2>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </div>
        <div
          id="categs"
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 20px 0",
          }}
        >
          {categorias ? (
            <>
              <h4 className="subtitle">
                {getText("select_the_type_of_query")}
              </h4>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {categorias &&
                  categorias.map((categ, key) => {
                    return (
                      <Grid item xs={12} md={4} key={key}>
                        <Link to="artic" spy={true} smooth={true} offset={-100}>
                          <Card
                            onClick={() => handleArticulos(categ.idCategoria)}
                            className="knowCard"
                            style={{
                              height: "210px",
                              display: "flex",
                              alignItems: "center",
                              backgroundColor:
                                categ.idCategoria === categoria
                                  ? datos.primaryColor
                                  : "white",
                              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <img
                              src={
                                categ.idCategoria === categoria
                                  ? categ.imagen.split(".")[0] + "_negativo.png"
                                  : categ.imagen
                              }
                              alt="imagen"
                              style={{
                                height: "50%",
                                maxHeight: "100px",
                                margin: "5rem 0 0 0",
                              }}
                            />
                            <CardContent>
                              <h2
                                style={{
                                  fontSize: "34px",
                                  color:
                                    categ.idCategoria === categoria
                                      ? "white"
                                      : "#4D4D4D",
                                  margin: "0 0 3rem 0",
                                }}
                              >
                                {categ["nombre" + datos.locale]}
                              </h2>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          ) : null}
        </div>
        <div
          id="artic"
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 80px 0",
          }}
        >
          {articulos && (
            <>
              <h4 className="subtitle">
                {getText("here_you_have_all_the_articles")}
              </h4>

              <TextField
                placeholder={getText("filter_by_title")}
                id="standard-bare"
                onChange={(e) => setSearchArtic(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <FilterAltIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { fontFamily: "Avenir-Next" },
                }}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "10px !important",
                  border: "none",
                  margin: "0 0 20px 0",
                }}
              />
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {articulos &&
                  articulos
                    .filter((articulo) =>
                      searchArtic
                        ? articulo["titulo" + datos.locale]
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .includes(
                              searchArtic
                                ?.toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                            )
                        : articulo["titulo" + datos.locale]
                    )
                    .map((artic, key) => {
                      return (
                        <>
                          <Grid item xs={12} md={6} lg={4}>
                            <Card className="articCard" key={key}>
                              <CardContent
                                style={{
                                  padding: "0px",
                                }}
                              >
                                <div
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      margin: "0 2rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontSize: "32px",
                                        color: "#4D4D4D",
                                      }}
                                    >
                                      {artic["titulo" + datos.locale]}
                                    </h2>
                                    <Typography
                                      variant="body2"
                                      color="black"
                                      fontSize={20}
                                      fontFamily="Avenir-Next"
                                      style={{
                                        textAlign: "center",
                                        color: "#808080",
                                      }}
                                    >
                                      {artic["descripcion" + datos.locale]}
                                    </Typography>
                                    <Grid
                                      container
                                      style={{
                                        margin: "1rem auto",
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "240px",
                                      }}
                                    >
                                      {artic["pdf" + datos.locale] && (
                                        <Grid item xs={3} className="logoKnow">
                                          <CardMedia
                                            className="logoItem"
                                            component="img"
                                            image={PdfImg}
                                            alt="pdf"
                                            sx={{
                                              width: "33px !important",
                                            }}
                                            onClick={() =>
                                              handleOpenPdf(
                                                "https://api.clientes.intexcorp.es/public/pdf" +
                                                  artic["pdf" + datos.locale]
                                              )
                                            }
                                          />
                                        </Grid>
                                      )}
                                      {artic.video && (
                                        <Grid item xs={3} className="logoKnow">
                                          <CardMedia
                                            className="logoItem"
                                            component="img"
                                            image={YouTubeImg}
                                            alt="youtube"
                                            sx={{
                                              width: "38px !important",
                                            }}
                                            onClick={() =>
                                              handleOpenVideo(artic.video)
                                            }
                                          />
                                        </Grid>
                                      )}
                                      <Grid
                                        item
                                        xs={3}
                                        className="logoKnow"
                                        style={{
                                          borderLeft: "1px solid #E6E6E6",
                                        }}
                                      >
                                        <CardMedia
                                          className="logoItem"
                                          component="img"
                                          image={SendImg}
                                          alt="send"
                                          sx={{
                                            width: "38px !important",
                                          }}
                                          onClick={() =>
                                            handleOpenSend(
                                              artic["titulo" + datos.locale],
                                              artic["pdf" + datos.locale],
                                              artic.video
                                            )
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        </>
                      );
                    })}
              </Grid>
            </>
          )}
        </div>
        <div
          id="artic"
          style={{
            width: "80%",
            display: "inline-block",
            margin: "-120px 0 80px 0",
          }}
        >
          {!grupo && search && (
            <>
              <h1 className="artBus" style={{ color: datos.primaryColor }}>
                {getText("your_search_results")}
              </h1>
              {articulosBusqueda ? (
                <>
                  <Grid container spacing={{ xs: 2, md: 3 }}>
                    {articulosBusqueda &&
                      articulosBusqueda.map((artic, key) => {
                        return (
                          <Grid item xs={12} md={6} lg={4}>
                            <Card className="articCard" key={key}>
                              <CardContent
                                style={{
                                  padding: "0px",
                                }}
                              >
                                <div
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      margin: "0 2rem",
                                      alignItems: "center",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontSize: "32px",
                                        color: "#4D4D4D",
                                      }}
                                    >
                                      {artic["titulo" + datos.locale]}
                                    </h2>
                                    <Typography
                                      variant="body2"
                                      color="black"
                                      fontSize={20}
                                      fontFamily="Avenir-Next"
                                      style={{
                                        textAlign: "center",
                                        color: "#808080",
                                      }}
                                    >
                                      {artic["descripcion" + datos.locale]}
                                    </Typography>
                                    <Grid
                                      container
                                      style={{
                                        margin: "1rem auto",
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "240px",
                                      }}
                                    >
                                      {artic["pdf" + datos.locale] && (
                                        <Grid item xs={3} className="logoKnow">
                                          <CardMedia
                                            className="logoItem"
                                            component="img"
                                            image={PdfImg}
                                            alt="pdf"
                                            sx={{
                                              width: "33px !important",
                                            }}
                                            onClick={() =>
                                              handleOpenPdf(
                                                "https://api.clientes.intexcorp.es/public/pdf" +
                                                  artic["pdf" + datos.locale]
                                              )
                                            }
                                          />
                                        </Grid>
                                      )}
                                      {artic.video && (
                                        <Grid item xs={3} className="logoKnow">
                                          <CardMedia
                                            className="logoItem"
                                            component="img"
                                            image={YouTubeImg}
                                            alt="youtube"
                                            sx={{
                                              width: "38px !important",
                                            }}
                                            onClick={() =>
                                              handleOpenVideo(artic.video)
                                            }
                                          />
                                        </Grid>
                                      )}
                                      <Grid
                                        item
                                        xs={3}
                                        className="logoKnow"
                                        style={{
                                          borderLeft: "1px solid #E6E6E6",
                                        }}
                                      >
                                        <CardMedia
                                          className="logoItem"
                                          component="img"
                                          image={SendImg}
                                          alt="send"
                                          sx={{
                                            width: "38px !important",
                                          }}
                                          onClick={() =>
                                            handleOpenSend(artic.video)
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                </>
              ) : (
                <p>{getText("no_results_found")}</p>
              )}
            </>
          )}
        </div>
        <ModalMedia
          pdf={pdf}
          video={video}
          nombre={nombre}
          close={handleClose}
          open={open}
          result={handleOpenSuccessError}
        />
      </div>
      <VideoBanner
        videos={[
          "https://www.youtube.com/embed/zq4qvKI8etM",
          "https://www.youtube.com/embed/zMCBZ_3jod8",
          "https://www.youtube.com/embed/9b-pgcJagCI",
        ]}
        color={"#1a5e83"}
        link={"https://www.youtube.com/channel/UCb9QSmgcvH1bMGkOVvk2sEA"}
      />
      <ModalSuccessError
        open={openSuccess}
        close={handleCloseSuccessError}
        type="success"
        text={getText("information_shared_successfully")}
      />

      <ModalSuccessError
        open={openError}
        close={handleCloseSuccessError}
        type="error"
        text={getText("it_seems_something_went_wrong_please_try_again")}
      />
      <Footer />
    </>
  );
};

export default Knowledge;
