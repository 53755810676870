import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { getText } from "../../lang/language";
import { getValue } from "../../slice";

import "./footer.scss";

const Footer = () => {
  const datos = useSelector(getValue);

  return (
    <Grid container className="footer">
      <Grid xs={12}>
        <img
          src={datos.logo}
          alt=""
          style={{ height: "30px", margin: "0rem 0 0.8rem 0" }}
        />
      </Grid>
      <Grid xs={12}>
        <div style={{ width: "100%", backgroundColor: "black" }}>
          <p
            style={{
              margin: "0",
              padding: "1rem",
              marginTop: "1rem",
              fontSize: "14px",
            }}
          >
            {" "}
            © {new Date().getFullYear()} INTEX -{" "}
            {getText(
              "official_INTEX_customer_service_for_Spain_and_Portugal_all_rights_reserved"
            )}
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default Footer;
