import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'datos',
  initialState: {
      value: {}
  },
  reducers: {
    change: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { change } = slice.actions;

export const getValue = state => state.datos.value;

export default slice.reducer;