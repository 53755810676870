import DescriptionIcon from "@mui/icons-material/Description";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DownloadIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { Navigate } from "react-router-dom";
import { Link } from "react-scroll";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Title from "../../components/title/title.component";
import { getText } from "../../lang/language";
import {
  getArticulos,
  getCategorias,
  getGrupos,
} from "../../services/catalogo.service";
import { getValue } from "../../slice";
import "./catalogo.container.scss";

const Catalogo = () => {
  const datos = useSelector(getValue);
  const [grupos, setGrupos] = useState();
  const [categorias, setCategorias] = useState();
  const [productos, setProductos] = useState();
  const [grupo, setGrupo] = useState();
  const [categoria, setCategoria] = useState();
  const [searchArtic, setSearchArtic] = useState();

  useEffect(() => {
    getGrupos(2).then((grupos) => setGrupos(grupos));
  }, []);

  const handleCategorias = (grupo) => {
    const getCat = async () => {
      const cat = await getCategorias(grupo);
      setCategorias(cat);
    };
    setGrupo(grupo);
    setCategoria(null);
    getCat();
    setProductos(null);
    setSearchArtic(null);
  };

  const handleArticulos = (categoria) => {
    const getArts = async () => {
      const prods = await getArticulos(categoria, datos.idUsuario);
      setProductos(prods);
    };
    setCategoria(categoria);
    setSearchArtic(null);
    getArts();
  };

  if (!sessionStorage.getItem("token")) return <Navigate to="/login" replace />;

  return (
    <>
      <Header page="/catalogue" />
      <Title title={getText("catalogue")} />
      <div
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Zoom>
          <h1
            style={{
              fontSize: isMobile ? "20px" : "30px",
              marginTop: "50px",
              color: datos.primaryColor,
            }}
          >
            {getText("click_on_the_category_you_want_to_consult")}
          </h1>
        </Zoom>
        <div
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 20px 0",
          }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {grupos &&
              grupos.map((card) => {
                return (
                  <Grid item xs={12} md={6} lg={4} key={card.idGrupo}>
                    <Link to="categs" spy={true} smooth={true} offset={-90}>
                      <Card
                        onClick={() => handleCategorias(card.idGrupo)}
                        className="knowCard"
                        style={{
                          backgroundImage:
                            card.idGrupo === grupo
                              ? `linear-gradient(0deg, ${datos.primaryColor}70,  ${datos.primaryColor}70),url(${card.imagen})`
                              : `linear-gradient(0deg, rgba(27, 27, 27, 0.4), rgba(27, 27, 27, 0.4)),url(${card.imagen})`,
                        }}
                      >
                        <CardContent>
                          <h2>{card["nombre" + datos.locale]}</h2>
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        </div>
        <div
          id="categs"
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 20px 0",
          }}
        >
          {categorias ? (
            <>
              <h4 className="subtitle">
                {getText("select_the_model_of_this_product")}
              </h4>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                sx={{ justifyContent: "center", display: "flex" }}
              >
                {categorias &&
                  categorias.map((categ) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        key={categ.idCategoria}
                      >
                        <Link to="artic" spy={true} smooth={true} offset={-100}>
                          <Card
                            className="cardCateg"
                            onClick={() => handleArticulos(categ.idCategoria)}
                            key={categ.idCategoria}
                            style={{
                              color: datos.primaryColor,
                              backgroundColor:
                                categ.idCategoria === categoria
                                  ? `${datos.primaryColor}70`
                                  : "white",
                              border:
                                categ.idCategoria === categoria
                                  ? "none"
                                  : "2px solid #F2F2F2",
                            }}
                          >
                            <div
                              style={{
                                height: "100%",
                                margin: "30px 20px 10px 20px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CardMedia
                                component="img"
                                image={categ.imagen ?? datos.logo}
                                alt="catimage"
                                style={{
                                  maxHeight: "50%",
                                  width: "auto",
                                  margin: "-25px 0 0 0",
                                }}
                              />
                              <CardContent
                                style={{ margin: "0", padding: "0" }}
                              >
                                <h2 style={{ fontSize: "28px" }}>
                                  {categ["nombre" + datos.locale]}
                                </h2>
                              </CardContent>
                            </div>
                          </Card>
                        </Link>
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          ) : null}
        </div>
        <div
          id="artic"
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 80px 0",
          }}
        >
          {productos ? (
            <>
              <h4 className="subtitle">
                {getText("here_you_have_all_the_articles")}
              </h4>
              <TextField
                placeholder={getText("filter_by_reference")}
                id="standard-bare"
                onChange={(e) => setSearchArtic(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <FilterAltIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { fontFamily: "Avenir-Next" },
                }}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "10px !important",
                  border: "none",
                  margin: "0 0 20px 0",
                }}
              />
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {productos &&
                  productos
                    .filter((articulo) =>
                      searchArtic
                        ? articulo.referencia
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .includes(
                              searchArtic
                                ?.toLowerCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                            )
                        : articulo.referencia
                    )
                    .map((artic) => {
                      return (
                        <Grid item xs={6} md={3} key={artic.referencia}>
                          <Card className="cardArticulo" key={artic.idProducto}>
                            <CardMedia
                              component="img"
                              height="100%"
                              width="100%"
                              image={
                                "https://www.distria.com/images/productos/large/" +
                                artic.imagen
                              }
                              style={{ padding: "0 0" }}
                              alt="articulo imagen"
                            />
                            <CardContent>
                              <Typography
                                variant="p"
                                className="subtitle"
                                rows={2}
                              >
                                {artic["nombre" + datos.locale]?.substr(0, 28)}
                              </Typography>
                              <br />
                              <Typography
                                variant="p"
                                className="subtitle textoRef"
                                sx={{ fontSize: "16px" }}
                                rows={2}
                              >
                                ref: {artic?.referencia}
                              </Typography>
                            </CardContent>
                            <Grid container style={{ width: "100%" }}>
                              <Grid item xs={6}>
                                {artic.ficha === 1 ? (
                                  <Button
                                    className="buttonArticulo"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: datos.primaryColor,
                                      color: "white",
                                      borderRadius: 0,
                                      borderRight: "1px solid #9c9c9c",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        "https://clientes.intexcorp.es/fichas_completas/" +
                                          artic.nombreFicha
                                      )
                                    }
                                    startIcon={<DownloadIcon />}
                                  >
                                    {getText("file")}
                                  </Button>
                                ) : (
                                  <Button
                                    className="button-no-PDF"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: "#D8D8D8",
                                      color: "white",
                                      borderRadius: 0,
                                      borderRight: "1px solid #9c9c9c",
                                    }}
                                    startIcon={<DownloadIcon />}
                                  >
                                    {getText("file")}
                                  </Button>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                {artic.instrucciones === 1 ? (
                                  <Button
                                    className="buttonArticulo"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      backgroundColor: datos.primaryColor,
                                      color: "white",
                                      borderRadius: 0,
                                      borderLeft: "1px solid #9c9c9c",
                                    }}
                                    onClick={() =>
                                      window.open(
                                        "https://clientes.intexcorp.es/manual/" +
                                          artic.nombreInstrucciones
                                      )
                                    }
                                    startIcon={<DescriptionIcon />}
                                  >
                                    {getText("instructions")}
                                  </Button>
                                ) : (
                                  <Button
                                    className="button-no-PDF"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      borderRadius: 0,
                                      backgroundColor: "#D8D8D8",
                                      color: "white",
                                      borderLeft: "1px solid #9c9c9c",
                                    }}
                                    startIcon={<DescriptionIcon />}
                                  >
                                    {getText("instructions")}
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      );
                    })}
              </Grid>
            </>
          ) : categoria ? (
            <>
              <h4 className="subtitle">
                {getText("here_you_have_all_the_articles")}
              </h4>
              <p> {getText("no_related_products_found")}</p>
            </>
          ) : null}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Catalogo;
