import { ErrorMessage } from "@hookform/error-message";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { Navigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ModalSuccessError from "../../components/modalSuccesError/modal-success-error";
import Title from "../../components/title/title.component";
import { getText } from "../../lang/language";
import { createConsult, getConsultTypes } from "../../services/contact.service";
import { obtenerToken } from "../../services/tickets.service";
import { getValue } from "../../slice";
import "./contact.container.scss";

const Contact = () => {
  const datos = useSelector(getValue);
  const [consults, setConsults] = useState();
  const [consultTypes, setConsultTypes] = useState();
  const [subconsultTypes, setSubconsultTypes] = useState();

  const [loading, setLoading] = useState(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCloseSuccessError = () => {
    setOpenSuccess(false);
    setOpenError(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getConsultTypes(datos.idEmpresa).then((consults) => {
      setConsults(consults);
      setConsultTypes(
        consults
          ?.filter((consult) => consult.idConsulta <= 10)
          .reduce((consultsMap, consult) => {
            consultsMap = [
              ...consultsMap,
              {
                label: consult["desc" + datos.locale],
                id: consult.idConsulta,
              },
            ];
            return consultsMap;
          }, [])
      );
    });
  }, [datos]);

  const handleSubconsults = (idConsulta) => {
    setSubconsultTypes(
      consults
        .filter((consult) => consult.idMadre === idConsulta)
        .reduce((consultsMap, consult) => {
          consultsMap = [
            ...consultsMap,
            {
              label: consult["desc" + datos.locale],
              id: consult.idConsulta,
            },
          ];
          return consultsMap;
        }, [])
    );
  };

  const handleSend = async (data) => {
    data = {
      ...data,
      tipo: consults.find((c) => c["desc" + datos.locale] === data.tipo)
        .valueSalesforce,
      subtipo: consults.find((c) => c["desc" + datos.locale] === data.subtipo)
        .valueSalesforce,
    };
    setLoading(true);
    global.token = await obtenerToken();
    global.fecha_token = new Date().toLocaleDateString();
    const res = await createConsult(data, datos.idSalesforce);
    if (res.status === 200) {
      setOpenSuccess(true);
    } else {
      setOpenError(true);
      setLoading(false);
    }
    setLoading(false);
  };

  if (!sessionStorage.getItem("token")) return <Navigate to="/login" replace />;

  return (
    <>
      <Header page="/contact" />
      <Title title={getText("contact")} className="tituloKnow" />
      <Zoom>
        <h1
          style={{
            fontSize: isMobile ? "20px" : "30px",
            margin: "50px 0 50px 0",
            color: datos.primaryColor,
          }}
        >
          {getText("send_us_your_doubts_and_we_will_help_you_solve_them")}
        </h1>
      </Zoom>

      <div className="contentContact">
        <form onSubmit={handleSubmit(handleSend)} noValidate autoComplete="off">
          <Grid
            container
            spacing={{ xs: 3 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} sm={6}>
              <Autocomplete
                className="textField"
                id="tipo"
                options={consultTypes ?? []}
                getOptionLabel={(option) => option.label}
                name="tipo"
                onChange={(event, value) => {
                  handleSubconsults(value?.id ?? null);
                }}
                ListboxProps={{ style: { fontFamily: "Avenir-Next" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getText("query_type") + "*"}
                    {...register("tipo", {
                      required: getText("the_query_type_is_required"),
                    })}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="tipo"
                render={({ message }) => <p className="redColor">{message}</p>}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                className="textField"
                id="subtipo"
                options={subconsultTypes ?? []}
                getOptionLabel={(option) => option?.label}
                name="subtipo"
                ListboxProps={{ style: { fontFamily: "Avenir-Next" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getText("query_subtype") + "*"}
                    {...register("subtipo", {
                      required: getText("the_query_subtype_is_required"),
                    })}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="subtipo"
                render={({ message }) => <p className="redColor">{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                className="textField"
                label={getText("email") + "*"}
                inputProps={{ style: { fontFamily: "Avenir-Next" } }}
                {...register("email", {
                  required: getText("the_email_is_required"),
                })}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <p className="redColor">{message}</p>}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={7}
                variant="outlined"
                placeholder="Mensaje"
                label={getText("message") + "*"}
                inputProps={{ style: { fontFamily: "Avenir-Next" } }}
                {...register("mensaje", {
                  required: getText("the_message_is_required"),
                })}
                style={{ width: "100%" }}
              />
              <ErrorMessage
                errors={errors}
                name="mensaje"
                render={({ message }) => <p className="redColor">{message}</p>}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="outlined"
            startIcon={loading ? null : <ForwardToInboxIcon />}
            style={{
              color: datos.primaryColor,
              borderColor: datos.primaryColor,
              fontFamily: "Avenir-Next",
              width: "130px",
              height: "60px",
              margin: "3rem 0 0 0",
            }}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type={"bars"} color={datos.primaryColor} />
            ) : (
              <span>{getText("send")}</span>
            )}
          </Button>
        </form>
      </div>
      <ModalSuccessError
        open={openSuccess}
        close={handleCloseSuccessError}
        type="success"
        text={getText("we_will_contact_you_shortly")}
      />

      <ModalSuccessError
        open={openError}
        close={handleCloseSuccessError}
        type="error"
        text={getText("it_seems_something_went_wrong_please_try_again")}
      />
      <Footer />
    </>
  );
};

export default Contact;
