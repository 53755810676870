import axios from 'axios';
import '../config';


export const getGrupos = async (empresa) => {
  
    const config = {
      headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}`}
    };
    
    return await axios.get(global.ruta+"/catalogo/grupos/"+empresa, config )
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
      
  }

  export const getCategorias = async (grupo) => {
  
    const config = {
      headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}`}
    };
    
    return await axios.get(global.ruta+"/catalogo/categorias/"+grupo, config )
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
      
  }

  export const getArticulos = async (categoria, idUsuario) => {
  
    const config = {
      headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}`}
    };
    
    return await axios.get(global.ruta+"/catalogo/articulos/"+categoria+"?user="+idUsuario, config )
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
      
  }