import AssignmentIcon from "@mui/icons-material/Assignment";
import EmailIcon from "@mui/icons-material/Email";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getText } from "../../../lang/language";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import LogoTicket from "../../../assets/images/tickets/ticket.png";
import {
  enviarMensaje,
  getDescripcionPorNombre,
  obtenerTicketsBusqueda,
  obtenerToken,
} from "../../../services/tickets.service";
import { getValue } from "../../../slice";
import ModalSuccessError from "../../modalSuccesError/modal-success-error";
import "./ticket-related.component.scss";

const TicketRelated = (props) => {
  const datos = useSelector(getValue);
  const [email, setEmail] = useState();
  const [ticket, setTicket] = useState();
  const [error, setError] = useState(false);
  const [ticketDetail, setDetail] = useState();
  const [message, setMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [descEstado, setDescEstado] = useState();
  const [descGestion, setDescGestion] = useState();
  const [textError, setTextError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  const [openSend, setOpenSend] = useState(false);

  const styles = {
    grupo: {
      borderRadius: "10px",
      maxWidth: "800px",
    },
    style: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      border: "1px solid ##E6E6E6",
      boxShadow: 24,
      borderRadius: "20px",
      p: 4,
    },
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (ticket && email) {
      setError(false);
      global.token = await obtenerToken();
      global.fecha_token = new Date().toLocaleDateString();
      const dev = await obtenerTicketsBusqueda(
        ("000" + ticket).slice(-8),
        email
      );
      setDetail(dev);
      if (dev.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
    } else setError(true);
    setLoading(false);
  };

  const handleCloseSuccessError = () => {
    setOpenSend(false);
  };

  const handleClose = () => {
    setTextError(false);
    setMessage(null);
    setOpen(false);
    setTicket(0);
  };

  const handleOpen = (ticket) => {
    setOpen(true);
    setTicket(ticket);
  };

  const handleSendMessage = async () => {
    setLoadingSend(true);
    global.token = await obtenerToken();
    global.fecha_token = new Date().toLocaleDateString();
    if (message && message !== "") {
      const res = await enviarMensaje(
        message,
        ticket,
        ticketDetail[0]["SuppliedEmail"],
        datos.nombre
      );
      if (res.status === 200) {
        setOpenSend(true);
      }
      handleClose();
    } else {
      setTextError(true);
    }
    setLoadingSend(false);
  };

  const getDesc = async (text, type) => {
    const data = await getDescripcionPorNombre(text);
    if (type === "estado" && !descEstado) {
      setDescEstado(data[0]["descripcion" + datos.locale]);
    }
    if (type === "gestion" && !descGestion) {
      setDescGestion(data[0]["descripcion" + datos.locale]);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            width: "90%",
          }}
          spacing={2}
        >
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              required
              id="outlined-basic"
              label={getText("incident_number")}
              variant="outlined"
              onChange={(e) => setTicket(e.target.value)}
              sx={{ width: "100%" }}
              inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={6} width="100%">
            <TextField
              required
              id="outlined-basic"
              label={getText("incident_email")}
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: "100%" }}
              inputProps={{ style: { fontFamily: "Avenir-Next" } }}
            />
          </Grid>
          <Grid item xs={8} md={3} lg={2} sx={{ maxHeight: "72px" }}>
            <Button
              variant="outlined"
              onClick={handleSubmit}
              startIcon={loading ? null : <SearchIcon />}
              style={{
                color: datos.primaryColor,
                borderColor: datos.primaryColor,
                width: "100%",
                height: "100%",
              }}
              disabled={loading}
            >
              {loading ? (
                <ReactLoading type={"bars"} color={datos.primaryColor} />
              ) : (
                <span>{getText("search")} </span>
              )}
            </Button>
          </Grid>
        </Grid>
        {error ? (
          <p className="redColor" style={{ margin: "10px 0" }}>
            {getText(
              "you_must_enter_the_ticket_number_and_email_to_be_able_to_show_the_ticket_you_are_looking_for"
            )}
          </p>
        ) : null}
        {notFound && !error ? (
          <p className="redColor" style={{ margin: "10px 0" }}>
            {getText("no_mateches_found")}
          </p>
        ) : null}
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "20px 50px 50px 50px",
            width: "90%",
          }}
        >
          {ticketDetail && ticketDetail.length > 0 ? (
            <Card elevation={5} sx={styles.grupo}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: datos.primaryColor }}
                    variant="rounded"
                  >
                    <AssignmentIcon />
                  </Avatar>
                }
                title={getText("incident_number")}
                subheader={ticketDetail[0]["CaseNumber"]
                  .replace(0, "")
                  .replace(0, "")
                  .replace(0, "")}
                subheaderTypographyProps={{ fontSize: "25px !important" }}
                sx={{ textAlign: "left" }}
              />
              <CardContent
                style={{
                  borderTop: "1px solid" + datos.primaryColor,
                  textAlign: "left",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ mb: 0.5 }}
                      variant="h8"
                      className="family-title"
                    >
                      {getText("name")}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1.5 }}
                      component="div"
                      className="family-text"
                    >
                      {ticketDetail[0]["SuppliedName"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ mb: 0.5 }}
                      variant="h8"
                      className="family-title"
                    >
                      {getText("description_of_the_incident_by_the_client")}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1.5 }}
                      component="div"
                      className="family-text"
                    >
                      {ticketDetail[0]["Description"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {" "}
                    <Typography
                      sx={{ mb: 0.5 }}
                      variant="h8"
                      className="family-title"
                    >
                      {getText("state")}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1.5 }}
                      component="div"
                      className="family-text"
                    >
                      {ticketDetail[0]["Status"]}{" "}
                      <IconButton
                        onClick={() =>
                          !descEstado
                            ? getDesc(ticketDetail[0]["Status"], "estado")
                            : setDescEstado(null)
                        }
                        sx={{ margin: "-8px 0 0 -5px", padding: "1px" }}
                      >
                        <InfoOutlinedIcon
                          sx={{ height: "18px" }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Typography>
                    {descEstado ? (
                      <Typography
                        sx={{
                          mb: 1.5,
                          mt: -1.3,
                          mr: 4,
                          fontSize: "14px",
                          color: datos.primaryColor,
                        }}
                        color="text.secondary"
                        className="family-text"
                      >
                        {descEstado}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{ mb: 0.5 }}
                      variant="h8"
                      className="family-title"
                    >
                      {getText("management_to_carry_out")}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1.5 }}
                      component="div"
                      className="family-text"
                    >
                      {ticketDetail[0]["CB_Gestion_del_ticket__c"]}

                      <IconButton
                        onClick={() =>
                          !descGestion
                            ? getDesc(
                                ticketDetail[0]["CB_Gestion_del_ticket__c"],
                                "gestion"
                              )
                            : setDescGestion(null)
                        }
                        sx={{ margin: "-8px 0 0 1px", padding: "1px" }}
                      >
                        <InfoOutlinedIcon
                          sx={{ height: "18px" }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Typography>
                    {descGestion ? (
                      <Typography
                        sx={{
                          mb: 1.5,
                          mt: -1.3,
                          mr: 4,
                          fontSize: "14px",
                          color: datos.primaryColor,
                        }}
                        color="text.secondary"
                        className="family-text"
                      >
                        {descGestion}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{ mb: 0.5 }}
                      variant="h8"
                      className="family-title"
                    >
                      {getText("email")}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1.5 }}
                      component="div"
                      className="family-text"
                    >
                      {ticketDetail[0]["SuppliedEmail"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{ mb: 0.5 }}
                      variant="h8"
                      className="family-title"
                    >
                      {getText("last_update_date")}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ mb: 1.5 }}
                      component="div"
                      className="family-text"
                    >
                      {ticketDetail[0]["LastModifiedDate"]
                        .replace(".000+0000", "")
                        .replace("T", " ")}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions style={{ backgroundColor: datos.primaryColor }}>
                <Button
                  size="large"
                  style={{ color: "white", margin: "0 auto" }}
                  onClick={() => handleOpen(ticketDetail[0]["Id"])}
                  className="family-text"
                >
                  <EmailIcon style={{ marginRight: "10px" }} />
                  {getText("contact_aftersales_service")}
                </Button>
              </CardActions>
            </Card>
          ) : (
            <Card
              elevation={5}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <Grid container>
                <Grid item xs={12} md={5} lg={4}>
                  <CardMedia
                    component="img"
                    height="250"
                    image={LogoTicket}
                    alt="logo-ticket"
                    sx={{ width: "250px", margin: "0 auto" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={7}
                  lg={8}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ margin: "auto 2rem", textAlign: "left" }}>
                    <h3 style={{ fontFamily: "HelveticaNeue-BoldCondensed" }}>
                      {getText("how_to_locate_the_incidents_of_your_clients")}
                    </h3>
                    <p className="subtitle">
                      {getText(
                        "enter_the_incident_number_and_the_customer_email_to_consult_all_the_detailed_information_and_make_communications_about_the_status_of_the_case"
                      )}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Card>
          )}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.style}>
          <div>
            <h3>{getText("do_you_have_any_questions_about_this_ticket")}</h3>
            <p className="subtitle">
              {getText(
                "write_to_us_explaining_the_reason_for_the_query_and_we_will_contact_the_client_as_soon_as_possible"
              )}
            </p>
            <form>
              <TextField
                required
                id="outlined-basic"
                name="mensaje"
                variant="outlined"
                multiline={true}
                minRows={6}
                onChange={(e) => setMessage(e.target.value)}
                style={{ margin: "20px 0", width: "100%" }}
              />
              {textError ? (
                <p className="redColor" style={{ margin: "0 0 30px 0" }}>
                  {getText("fill_in_the_body_of_the_message")}
                </p>
              ) : null}

              <Button
                variant="outlined"
                startIcon={loadingSend ? null : <ForwardToInboxIcon />}
                style={{
                  color: datos.primaryColor,
                  borderColor: datos.primaryColor,
                  fontFamily: "Avenir-Next",
                  width: "130px",
                  height: "60px",
                  float: "right",
                }}
                onClick={handleSendMessage}
                disabled={loading}
              >
                {loadingSend ? (
                  <ReactLoading type={"bars"} color={datos.primaryColor} />
                ) : (
                  <span>{getText("send")}</span>
                )}
              </Button>
            </form>
          </div>
        </Box>
      </Modal>
      <ModalSuccessError
        open={openSend}
        close={handleCloseSuccessError}
        type="success"
        text={getText("we_will_contact_with_the_customer_shortly")}
      />
    </>
  );
};

export default TicketRelated;
