import axios from "axios";
import "../config";

export const getGrupos = async (empresa) => {

  const config = {
    headers: {
      Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
    },
  };

  return await axios
    .get(global.ruta + "/learning/grupos/" + empresa, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
