import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { Navigate } from "react-router-dom";
import PdfImg from "../../assets/images/logos_archivos/pdf.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ModalMedia from "../../components/modalMedia/modal-media.component";
import Title from "../../components/title/title.component";
import VideoBanner from "../../components/videoBanner/video-banner.component";
import { getText } from "../../lang/language";
import { getGrupos } from "../../services/learning.service";
import { getValue } from "../../slice";
import "./learning.container.scss";

const Learning = () => {
  const datos = useSelector(getValue);
  const [grupos, setGrupos] = useState();
  const [video, setVideo] = useState();
  const [pdf, setPdf] = useState();
  const [open, setOpen] = React.useState(false);

  const handleOpenPdf = (value) => {
    setPdf(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVideo(null);
    setPdf(null);
  };

  useEffect(() => {
      getGrupos(2).then((gr) => setGrupos(gr));
  }, []);

  if (!sessionStorage.getItem("token")) return <Navigate to="/login" replace />;

  return (
    <>
      <Header page="/learning" />
      <Title title={getText("formation")} />
      <div
        id="grups"
        style={{
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Zoom>
          <h1
            style={{
              fontSize: isMobile ? "20px" : "30px",
              marginTop: "50px",
              color: datos.primaryColor,
            }}
          >
            {getText("get_to_know_our_products_in_depth")}
          </h1>
          <h4 className="subtitle">
            {getText("access_the_information_in_PDF_or_video_format")}
          </h4>
        </Zoom>
        <div
          style={{
            width: "80%",
            display: "inline-block",
            margin: "20px 0 80px 0",
          }}
        >
          {grupos && (
            <>
              <Grid
                container
                spacing={{ xs: 1, md: 3 }}
                direction="row"
                style={{
                  justifyContent: grupos.length < 3 ? "center" : null,
                }}
              >
                {grupos.map((grupo, key) => {
                  return (
                    <Grid item xs={12} md={3} key={key}>
                      <Card
                        style={{
                          minHeight: isMobile ? "150px" : "250px",
                          zIndex: 2,
                        }}
                        className="knowCard"
                      >
                        <CardContent
                          style={{
                            backgroundImage: `linear-gradient(0deg, rgba(27, 27, 27, 0.3), rgba(27, 27, 27, 0.3)),url(${grupo.imagen})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            color: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            minHeight: isMobile ? "150px" : "250px",
                            padding: 0,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            style={{ color: "white", fontWeight: "bold" }}
                          >
                            <h2>{grupo["nombre" + datos.locale]}</h2>
                          </Typography>
                        </CardContent>
                      </Card>
                      <div style={{ marginTop: "-1.8rem", zIndex: 1 }}>
                        <Grid
                          container
                          style={{
                            margin: "0 auto",
                            display: "flex",
                            justifyContent: "center",
                            width: "240px",
                          }}
                        >
                          {grupo["pdf" + datos.locale] && (
                            <Grid item xs={3} className="logoKnow">
                              <div
                                className="fondoLogo"
                                onClick={() =>
                                  handleOpenPdf(
                                    "https://api.clientes.intexcorp.es/public" +
                                      grupo["pdf" + datos.locale]
                                  )
                                }
                              >
                                <CardMedia
                                  className="logoItem"
                                  component="img"
                                  image={PdfImg}
                                  alt="pdf"
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </div>

        <ModalMedia pdf={pdf} video={video} close={handleClose} open={open} />
        <VideoBanner
          videos={[
            "https://www.youtube.com/embed/CFQX-JQ5hCc",
            "https://www.youtube.com/embed/TIgu8uIWZbM",
            "https://www.youtube.com/embed/DzdUwlGejfs",
          ]}
          color={datos.primaryColor}
          link={"https://www.youtube.com/channel/UCDLqHsVKT-STU-bcJTh59ow"}
        />
      </div>
      <Footer />
    </>
  );
};

export default Learning;
