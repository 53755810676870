import es from "../lang/es.json";
import pt from "../lang/pt.json";
import en from "../lang/en.json";
import store from "../store";

const LANG_ID = {
  es: 1,
  pt: 2,
};

export const getText = (value) => {
  switch (store.getState().datos.value.idIdioma) {
    case LANG_ID.es:
      return es[value];
    case LANG_ID.pt:
      return pt[value];
    default:
      return en[value];
  }
};
